$black: #444;
$blue: #005da3;
$red: #e05939;

@mixin background-size($val) {
	-webkit-background-size: $val;
	-moz-background-size: $val;
	-o-background-size: $val;
	background-size: $val; }

ul.sidebar-page {
	list-style-type: none;
	li {
		text-transform: uppercase;
		letter-spacing: 2px;
		a {
			display: block;
			color: $black;
			padding: 10px;
			line-height: 22px;
			font-size: 14px;
			font-weight: 600;
			&.active {
				color: $blue; } } } }

.bold {
	font-weight: bold; }

.lists {
	margin-bottom: 30px;
	a {
		color: $black;
		&:hover h5 {
			color: $blue; } }
	h5 {
		margin-top: 0; } }

.pagination > .active > span {
	background-color: $blue;
	border-color: $blue; }
.pagination > li > a {
	color: $blue; }

h3.details {
	font-size: 20px;
	border-bottom: 2px solid $black; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover {
	color: $red; }

.thumbnail {
	.thu {
		width: 100%;
		height: 200px;
		@include background-size(cover);
		background-position: center;
		p.place {
			background-color: rgba(0,93,163,0.8);
			color: white;
			padding: 3px; } }
	.caption {
		height: 100px;
		h4.orange {
			color: $red; }
		.label {
			margin-right: 5px; } }
	.footer {
		padding: 9px; } }

div.zabuto_calendar .table tr td.event div.day, div.zabuto_calendar ul.legend li.event {
	background-color: rgba(0,93,163,0.2) !important; }

h6 {
	font-size: 90px;
	color: #c6c6c6;
	padding-top: 10px;
	line-height: 70px; }

.container-agenda {
	position: relative;
	&:hover {
		.agenda {
			opacity: 0.3; }
		.middle {
			opacity: 1; } }
	.agenda {
		opacity: 1;
		display: block;
		width: 100%;
		height: auto;
		transition: .5s ease;
		backface-visibility: hidden; }
	.middle {
		transition: .5s ease;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		width: 100%; }
	.text {
		background-color: $blue;
		color: white;
		padding: 10px;
		font-size: 20px;
		i {
			font-size: 35px;
			margin-right: 10px; }
		a {
			color: white; } } }

.max-h-170 {
	max-height: 170px; }

.bg-blue {
	background-color: rgba(0,93,163,0.8);
	padding: 10px;
	color: white;
	min-height: 150px;
	margin-left: -10px;
	a, a:hover {
		color: white; } }

a.no-dec:hover {
	text-decoration: none; }

a.recinto-main {
	&:hover {
		text-decoration: none; }
	h2 {
		background-color: #005DA3;
		margin: 0;
		padding-top: 20px;
		padding-bottom: 10px;
		color: white; }
	img {
		width: 100%; } }

ul.recinto-main {
	padding: 0;
	list-style-type: none;
	li a {
		color: #333;
		padding: 8px;
		display: block;
		&:hover {
			background-color: #005DA3;
			color: white;
			margin-right: -30px; } } }

h5.municipality {
	display: block;
	color: #444;
	padding: 10px;
	line-height: 22px;
	font-size: 14px;
	font-weight: 600;
	margin: 0;
	&:hover {
		color: $blue; } }
.btn-municipality:hover {
	text-decoration: none; }

img.ghdp {
	height: 100% !important;
	@media(max-width: 725px) {
		height: 60px !important; }
	@media(max-width: 612px) {
		height: 50px !important; }
	@media(max-width: 535px) {
		height: 40px !important; }
	@media(max-width: 457px) {
		height: 30px !important; }
	@media(max-width: 380px) {
		height: 20px !important; }
	@media(max-width: 303px) {
		height: 10px !important; } }

.title-container-banner {
	padding: 20px;
	position: absolute;
	bottom: 0;
	background-color: rgba(0, 93, 163, 0.6);
	width: 100%;
	h2 {
		margin: 0;
		padding: 0;
		color: #fff;
		font-size: 16px; } }
